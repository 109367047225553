@tailwind base;
@tailwind components;
@tailwind utilities;

#fc_frame.fc-mobile-view {
    bottom: 10px !important;
    right: 10px !important;
    z-index: 99 !important;
}

a {
    color: inherit;
    text-decoration: none;
}
